<template>
    <div class="ui-button"
         :class="[colorClass, sizeClass, disabledClass]"
         @click.stop.prevent="onClick"
    >
        <slot></slot>
    </div>
</template>

<script>
const DEFAULT_COLOR = 'blue';
const DEFAULT_SIZE = 'big';

export default {
    computed: {
        colorClass() {
            return `ui-button--${this.$attrs.color || DEFAULT_COLOR}`;
        },
        sizeClass() {
            return `ui-button--${this.$attrs.size || DEFAULT_SIZE}`;
        },
        disabledClass() {
            return this.$attrs.disabled ? 'ui-button--disabled' : '';
        }
    },
    methods: {
        onClick(event) {
            this.$emit('clicked', event);
        }
    }
};
</script>

<style lang="scss">
@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.ui-button {
    @include font-open-sans(14, 700);
    color: #fff;
    background: $primaryColor;
    display: inline-block;
    padding: 20px 32px;
    border-radius: 24px;
    cursor: pointer;
    user-select: none;
    text-align: center;
    justify-content: center;
    align-items: center;
    line-height: 0;

    &:hover {
        filter: brightness(110%);
    }

    &:active {
        filter: brightness(90%);
    }

    &--blue {
        background: $primaryColor;
    }

    &--transparent {
        background: transparent;
        border: 1px solid $primaryColor;
        color: $primaryColor;

        &:hover {
            background: $primaryColor;
            color: #fff;
        }
    }

    &--disabled {
        pointer-events: none;
        background-color: #7b7676;
    }

    &--small {
        padding: 12px 24px;
        font-size: .75rem;
    }
}
</style>
